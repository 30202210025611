<template>
    <div class="login-box-right">
        <div
                class="qie-box"
                @click="toQrcode"
        ></div>
        <div class="title-box">
            <i class="iconfont">&#xe660;</i>
            公会后台管理系统
        </div>
        <div class="title-container">账号登录</div>
        <a-form :form="form" class="login-form" @submit="handleSubmit">
            <a-form-item>
                <a-input
                        v-decorator="[
                'account',
                { rules: [{ required: true, message: '请输入QQ号!' }] },
              ]"
                        placeholder="请输入QQ号"
                        :allowClear="true"
                >
                    <a-icon
                            slot="prefix"
                            type="user"
                            style="color: rgba(0, 0, 0, 0.25)"
                    />
                </a-input>
            </a-form-item>
            <a-form-item>
                <a-input-password
                        v-decorator="[
                'password',
                { rules: [{ required: true, message: '请输入密码!' }] },
              ]"
                        type="password"
                        placeholder="密码"
                        :allowClear="true"
                >
                    <a-icon
                            slot="prefix"
                            type="lock"
                            style="color: rgba(0, 0, 0, 0.25)"
                    />
                </a-input-password>
            </a-form-item>
            <a-form-item>
                <a-input
                        v-decorator="['captcha',{ rules: [{ required: true, message: '请输入密码!' }] },]"
                        type="text"
                        placeholder="验证码"
                        :allowClear="true"
                >
                    <a-icon
                            slot="prefix"
                            type="lock"
                            style="color: rgba(0, 0, 0, 0.25)"
                    />
                </a-input>
                <img id="captcha" :src="captcha_img" alt="二维码" @click="refreshImg()">
            </a-form-item>
            <a-form-item>
                <a-checkbox
                        v-decorator="[
                'remember',
                {
                  valuePropName: 'checked',
                  initialValue: true,
                },
              ]"
                >
                    记住密码
                </a-checkbox>
                <!-- <a class="login-form-forgot" @click="toQrcode()" > 忘记密码？ </a> -->
                <a-button
                        type="primary"
                        html-type="submit"
                        class="login-form-button"
                >
                    点击登录
                </a-button>
                <div class="reg-txt">
                    <!--                    <a @click="toQrcode()"> 立即注册 </a>-->
                </div>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
    import config from "@/common/api/index"

    export default {
        name: "LoginAccount",
        components: {},
        data() {
            return {
                logging: false,
                error: "",
                form: this.$form.createForm(this, {name: "normal_login"}),
                captcha_img: `${config.baseURL}/v2/captcha/create`,
            };
        },
        computed: {},
        mounted() {

        },
        created() {
            if (this.$route.query.code) this.loginByCode(this.$route.query.code);
        },
        methods: {
            refreshImg() {
                let time = new Date().getTime();
                this.captcha_img = `${config.baseURL}/v2/captcha/create?v=${time}`;
            },
            loginByCode(code) {
                this.$api
                    .post(`/v1/user/miniProgramLogin`, {code: code})
                    .then((resp) => {
                        if (resp.code == 200) {
                            window.localStorage.setItem("token", resp.data.token);
                            this.$router.push("/");
                        } else if (resp.code === 106) {
                            this.$router.push('/reg')
                        } else {
                            this.$message.error(resp.message);
                        }
                        this.logging = false;
                    })
                    .catch((e) => {
                        this.logging = false;
                        throw e;
                    });
            },

            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err) => {
                    if (!err) {
                        this.logging = true;
                        const account = this.form.getFieldValue("account");
                        const password = this.form.getFieldValue("password");
                        const captcha = this.form.getFieldValue("captcha");
                        let params = {
                            account,
                            password,
                            captcha,
                        };
                        console.log(params);
                        this.$api
                            .post(`/v1/user/login`, params)
                            .then((resp) => {
                                if (resp.code == 200) {
                                    window.localStorage.setItem("token", resp.data.token);
                                    this.$router.push("/");
                                } else if (resp.code === 106) {
                                    this.$router.push('/reg')
                                } else {
                                    /*刷新验证码*/
                                    this.refreshImg();
                                    this.$message.error(resp.message);
                                }
                                this.logging = false;
                            })
                            .catch((e) => {
                                this.logging = false;
                                throw e;
                            });
                    }
                });
            },
            toQrcode() {
                this.$router.push('/login/qrcode')
            },
            toReg() {
                this.$router.push('/login/reg')
            }
        },
    };
</script>

<style lang="less" scoped>
    .login-box-right {
        width: 50%;
        height: 680px;
        position: relative;

        .qie-box {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            z-index: 9;
            width: 62px;
            height: 62px;
            background-image: url(https://hxh-1305060147.cos.ap-chengdu.myqcloud.com/bAdmin/login/qie2.png);
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
        }

        .title-box {
            font-size: 20px;
            font-weight: bold;
            color: #199aec;
            text-align: center;
            margin-top: 15px;

            .iconfont {
                line-height: 26px;
            }
        }

        .title-container {
            font-size: 36px;
            font-weight: bold;
            color: #ffffff;
            margin-top: 50px;
            text-align: center;
        }

        .login-form {
            margin: 66px auto;
            max-width: 300px;

            .login-form-forgot {
                float: right;
            }

            .login-form-button {
                width: 100%;
            }

            /deep/ .ant-checkbox-wrapper {
                color: #1890ff;
            }
        }
    }

    .reg-txt {
        width: 100%;
        text-align: center;
    }

    @media screen and(max-width: 750px) {
        .login-box-right {
            .title-container {
                font-size: 24px;
            }

            .login-form {
                margin: 66px auto;
                max-width: 300px;

                .login-form-button {
                    width: 100%;
                }
            }
        }
    }


</style>
